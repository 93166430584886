const Dashboard = () => import("../layouts/dashboard.vue");
const ListEmails = () => import("../pages/emails/listEmails");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const emailRoutes = [
  {
    path: "/emails",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ListEmails,
      },
    ],
  },
];
