import http from "../plugins/http";
const baseUrl = "/alert";

const initState = () => ({
  alert: {
    id: "",
    titulo: "",
    mensagem: "",
    data_expira: "",
    hora_expira: "",
  },
});

const state = {
  alert: initState().alert,
  alerts: [],
  loadMore: true,
  isSearching: false,
  isLoading: false,
  isLoadingSearch: false,
};

const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setAllAlerts(state, values) {
    state.alerts = values;
    state.loadMore = values.length === 20;
  },
  setMoreAlerts(state, values) {
    state.loadMore = values.length === 20;
    state.alerts = [...state.alerts, ...values];
  },
  setOneAlert(state, value) {
    const data_expira = value.data_expira.slice(0, 10);
    const hora_expira = value.data_expira.slice(11, 16);

    state.alert = { ...value, data_expira, hora_expira };
  },
  setRemoveAlert(state, id) {
    state.alerts = state.alerts.filter((alert) => alert.id !== id);
  },
  clearAlertValues(state) {
    state.alert = initState().alert;
  },
};

const actions = {
  async getAllAlerts({ commit }) {
    commit("setIsLoading", true);
    try {
      const { data } = await http.get(`${baseUrl}`);
      commit("setAllAlerts", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoading", false);
  },
  async getMoreAllAlerts({ commit }, page) {
    commit("setIsLoading", true);
    try {
      const url = `${baseUrl}?page=${page}`;
      const { data } = await http.get(url);
      commit("setMoreAlerts", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoading", false);
  },
  async getOneAlert({ commit }, id) {
    try {
      const { data } = await http.get(`${baseUrl}/${id}`);
      commit("setOneAlert", data);
    } catch (err) {
      console.log(err);
    }
  },
  async createAlert({ commit }, values) {
    try {
      await http.post(baseUrl, values);
      // commit("setOneAlert", data);
      commit(
        "setToastedSuccess",
        { message: "Notificação criada com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Não foi póssivel criar a notificação" },
        { root: true }
      );
    }
  },
  async updateAlert({ commit }, { id, ...values }) {
    try {
      const url = `${baseUrl}/${id}`;
      await http.put(url, values);
      // commit("setOneAlert", data);
      commit(
        "setToastedSuccess",
        { message: "Notificação atualizada com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Não foi póssivel atualizar a notificação" },
        { root: true }
      );
    }
  },
  async removeAlert({ commit }, id) {
    try {
      const url = `${baseUrl}/${id}`;
      const { data } = await http.delete(url);
      commit("setRemoveAlert", data.id);
      commit(
        "setToastedSuccess",
        { message: "Notificação removida com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Erro ao remover a notificação" },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
