import http from "../plugins/http";
import router from "../router";

const state = {
  token: "",
  user: {
    id: "",
    name: "",
    email: "",
  },
};

const mutations = {
  setToken(state, value) {
    state.token = value.token;
  },
  setUserInfo(state, value) {
    state.user = value;
  },
};

const getters = {
  isAuth(state) {
    return !!state.token;
  },
};

const actions = {
  async login({ commit }, { email, password }) {
    try {
      const { data } = await http.post("auth/login", { email, password });
      http.defaults.headers.common.Authorization = `Bearer ${data.token}`;

      const { data: userInfo } = await http.get("user/me");

      commit("setToken", data);
      commit("setUserInfo", userInfo);
      router.push("/produtos");
    } catch (err) {
      commit(
        "setToasted",
        {
          title: "ERRO",
          message: err.response.data.error,
          variant: "danger",
          visible: true,
        },
        { root: true }
      );
    }
  },
  async logout({ commit }) {
    await http.post("auth/logout");
    http.defaults.headers.common.Authorization = "";
    commit("setToken", { token: "" });
  },
  async forgotPassword({ commit }, values) {
    try {
      await http.post("auth/forgot-password", values);
      commit(
        "setToastedSuccess",
        { message: "Email enviado com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: err.response.data.error },
        { root: true }
      );
    }
  },
  async changePass({ commit }, { form, token }) {
    try {
      await http.post(`auth/change/${token}`, form);
      commit(
        "setToastedSuccess",
        { message: "Senha alterada com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: err.response.data.error },
        { root: true }
      );
    }
  },

  async refresh({ commit, state }) {
    try {
      const { data } = await http.post("auth/refresh");
      http.defaults.headers.common.Authorization = `Bearer ${data.token}`;

      if (!state.user.id) {
        const { data: userInfo } = await http.get("user/me");
        commit("setUserInfo", userInfo);
      }

      commit("setToken", data);
    } catch (err) {
      console.log(err);
    }
  },
  async changePassLoggedIn({ commit }, value) {
    try {
      const { data } = await http.post("auth/change-password", value);
      http.defaults.headers.common.Authorization = `Bearer ${data.token}`;

      commit("setToken", data);
      commit(
        "setToastedSuccess",
        { message: "Senha alterada com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Não foi possível alterar a senha" },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
