const Dashboard = () => import("../layouts/dashboard.vue");
const DetailsProducts = () => import("../pages/products/detailsProduct.vue");
const ListProducts = () => import("../pages/products/listProducts.vue");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const productRoutes = [
  {
    path: "/produtos",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ListProducts,
      },
      {
        path: ":id/editar",
        component: DetailsProducts,
      },
    ],
  },
];
