const Dashboard = () => import("../layouts/dashboard.vue");
const ListClients = () => import("../pages/clients/listClients");
const DetailsCelients = () => import("../pages/clients/detailsClients");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const clientRoutes = [
  {
    path: "/clientes",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ListClients,
      },
      {
        path: ":id/detalhes",
        component: DetailsCelients,
      },
    ],
  },
];
