import http from "../plugins/http";
const baseUrl = "/box";

const initState = () => ({
  box: {
    id: "",
    descricao: "",
    altura: "",
    largura: "",
    comprimento: "",
  },
});

const state = {
  box: initState().box,
  boxes: [],
  loadMore: true,
  isSearching: false,
  isLoading: false,
  isLoadingSearch: false,
};

const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setAllBoxes(state, values) {
    state.boxes = values;
    state.loadMore = values.length === 20;
  },
  setMoreBoxes(state, values) {
    state.loadMore = values.length === 20;
    state.boxes = [...state.boxes, ...values];
  },
  setOneBox(state, value) {
    state.box = value;
  },
  setRemoveBox(state, id) {
    state.boxes = state.boxes.filter((box) => box.id !== id);
  },
  clearBoxValues(state) {
    state.box = initState().box;
  },
};

const getters = {
  formattedBoxes(state) {
    return state.boxes.map(
      ({ id, descricao, altura, largura, comprimento }) => ({
        value: id,
        text: `${descricao} - A ${altura}cm x L ${largura}cm x C ${comprimento}cm`,
      })
    );
  },
};

const actions = {
  async getAllBoxes({ commit }) {
    commit("setIsLoading", true);
    try {
      const { data } = await http.get(`${baseUrl}`);
      commit("setAllBoxes", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoading", false);
  },
  async getMoreAllBoxes({ commit }, page) {
    commit("setIsLoading", true);
    try {
      const url = `${baseUrl}?page=${page}`;
      const { data } = await http.get(url);
      commit("setMoreBoxes", data);
    } catch (err) {
      console.log(err);
    }
    commit("setIsLoading", false);
  },
  async getOneBox({ commit }, id) {
    try {
      const { data } = await http.get(`${baseUrl}/${id}`);
      commit("setOneBox", data);
    } catch (err) {
      console.log(err);
    }
  },
  async createBox({ commit }, values) {
    try {
      const { data } = await http.post(baseUrl, values);
      commit("setOneBox", data);
      commit(
        "setToastedSuccess",
        { message: "Caixa criada com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Não foi póssivel criar caixa" },
        { root: true }
      );
    }
  },
  async updateBox({ commit }, { id, ...values }) {
    try {
      const url = `${baseUrl}/${id}`;
      const { data } = await http.put(url, values);
      commit("setOneBox", data);
      commit(
        "setToastedSuccess",
        { message: "Caixa atualizada com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Não foi póssivel atualizar caixa" },
        { root: true }
      );
    }
  },
  async removeBox({ commit }, id) {
    try {
      const url = `${baseUrl}/${id}`;
      const { data } = await http.delete(url);
      commit("setRemoveBox", data.id);
      commit(
        "setToastedSuccess",
        { message: "Caixa removida com sucesso" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Erro ao remover caixa" },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
