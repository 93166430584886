import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { alertRoutes } from "./alerts.routes";
import { boxRoutes } from "./boxes.routes";
import { clientRoutes } from "./client.routes";
import { configRoutes } from "./config.routes";
import { emailRoutes } from "./email.routes";
import { exportsRoutes } from "./exports.routes";
import { operationRoutes } from "./operation.routes";
import { productRoutes } from "./product.routes";
import { representativeRoutes } from "./representative.routes";
import { userRoutes } from "./user.routes";
import { weCallYouRoutes } from "./we-call-you.routes";
import { brandRoutes } from "./brand.routes";
import { feedbackRoutes } from "./feedback.routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../pages/index.vue"),
    },
    {
      path: "/troca-senha/:token",
      name: "changePassowrd",
      component: () => import("../pages/changePassword.vue"),
    },
    ...productRoutes,
    ...representativeRoutes,
    ...operationRoutes,
    ...boxRoutes,
    ...emailRoutes,
    ...alertRoutes,
    ...userRoutes,
    ...configRoutes,
    ...exportsRoutes,
    ...weCallYouRoutes,
    ...clientRoutes,
    ...brandRoutes,
    ...feedbackRoutes,
  ],
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters["auth/isAuth"]) {
    await store.dispatch("auth/refresh");
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isAuth"]) {
      next();
    } else {
      next("/");
    }
  } else {
    if (store.getters["auth/isAuth"]) {
      next("/produtos");
    } else {
      next();
    }
  }
});

export default router;
