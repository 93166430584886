import axios from "axios";
import jwtDecode from "jwt-decode";
import store from "../store";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? "https://api-panel.lvj.ind.br"
    : "http://localhost:4000";

const http = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
});

http.interceptors.request.use(
  async (config) => {
    const token = store.state.auth.token;
    const originalRequest = config;
    if (token) {
      const { exp } = jwtDecode(token);
      if (Date.now() >= exp * 1000) {
        const { data } = await axios.post(`${apiUrl}/auth/refresh`, null, {
          withCredentials: true,
        });
        originalRequest.headers.Authorization = `Bearer ${data.token}`;
        store.commit("auth/setToken", data);
      } else {
        originalRequest.headers.Authorization = `Bearer ${token}`;
      }
    }
    return originalRequest;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
