import http from "../plugins/http";
import { formatRepresentative } from "../utils/formatRepresentative";

const initState = () => ({
  representative: {
    id: 0,
    nome: "",
    link_foto: "",
    regiao_atendimento: "",
    contato1: "",
    whatsapp1: 0,
    contato2: "",
    whatsapp2: 0,
    contato3: "",
    whatsapp3: 0,
    contato4: "",
    whatsapp4: 0,
    email: "",
    AC: false,
    AL: false,
    AP: false,
    AM: false,
    BA: false,
    CE: false,
    DF: false,
    ES: false,
    GO: false,
    MA: false,
    MT: false,
    MS: false,
    MG: false,
    PA: false,
    PB: false,
    PR: false,
    PE: false,
    PI: false,
    RJ: false,
    RN: false,
    RS: false,
    RO: false,
    RR: false,
    SC: false,
    SP: false,
    SE: false,
    TO: false,
    ativo: false,
  },
});

const state = {
  isLoading: false,
  isSearching: false,
  showOverlay: false,
  representative: initState().representative,
  representatives: [],
};

const mutations = {
  setLoading: (state, value) => (state.isLoading = value),
  setSearching: (state, value) => (state.isSearching = value),
  setOverlay: (state, value) => (state.showOverlay = value),
  setRepresentatives(state, value) {
    state.representatives = value;
  },
  setOneRepresentative(state, value) {
    state.representative = value;
  },
  setNewRepresentative(state, value) {
    state.representative = value;
    state.representatives.push(value);
  },
  resetRepresentative(state) {
    state.representative = initState().representative;
  },
  setUpdatedRepresentative(state, value) {
    const index = state.representatives.findIndex((e) => e.id == value.id);
    state.representatives.splice(index, 1, value);
  },
  setMoreRepresentatives(state, value) {
    state.representatives.push(...value);
  },
};

const actions = {
  async getAllRepresentatives({ commit }) {
    commit("setSearching", false);
    const { data } = await http.get("representative?page=1");
    commit("setRepresentatives", data);
  },
  async getOneRepresentative({ commit }, id) {
    const { data } = await http.get(`representative/${id}`);
    commit("setOneRepresentative", data);
  },
  async getMoreRepresentatives({ commit }, page) {
    commit("setLoading", true);
    const { data } = await http.get(`representative?page=${page}`);

    commit("setMoreRepresentatives", data);
    commit("setLoading", false);
  },
  async searchProducts({ commit }, search) {
    commit("setSearching", true);

    const { data } = await http.get(`product/search?page=1&search=${search}`);
    commit("setRepresentatives", data);
  },
  async getMoreSearch({ commit }, { page, search }) {
    commit("setSearching", true);
    commit("setLoading", true);

    const { data } = await http.get(
      `product/search?page=${page}&search=${search}`
    );

    commit("setMoreRepresentatives", data);
    commit("setLoading", false);
  },
  async createRepresentative({ commit }, values) {
    try {
      let formatedObj = formatRepresentative(values);

      const { data } = await http.post("representative", formatedObj);

      commit("setNewRepresentative", data);
      commit(
        "setToasted",
        {
          title: "Sucesso",
          message: "Representante criado com sucesso!",
          variant: "success",
          visible: true,
        },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToasted",
        {
          title: "ERRO",
          message: err.response.data.error,
          variant: "danger",
          visible: true,
        },
        { root: true }
      );
    }
  },
  async updateRepresentative({ commit }, values) {
    try {
      let formatedObj = formatRepresentative(values);

      const { data } = await http.put(
        `representative/${formatedObj.id}`,
        formatedObj
      );

      commit("setUpdatedRepresentative", data);
      commit(
        "setToasted",
        {
          title: "Sucesso",
          message: "Representante atualizado com sucesso!",
          variant: "success",
          visible: true,
        },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToasted",
        {
          title: "ERRO",
          message: err.response.data.error,
          variant: "danger",
          visible: true,
        },
        { root: true }
      );
    }
  },
  async deactivateRepresentative({ commit }, { id, values }) {
    const { data } = await http.patch(`representative/${id}`, values);

    commit("setUpdatedRepresentative", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
