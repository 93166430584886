const Dashboard = () => import("../layouts/dashboard");
const DetailsRepresentative = () =>
  import("../pages/representative/detailsRepresentantes.vue");
const ListRepresentative = () =>
  import("../pages/representative/listRepresentantes.vue");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const representativeRoutes = [
  {
    path: "/representantes",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ListRepresentative,
      },
      {
        path: "criar",
        component: DetailsRepresentative,
      },
      {
        path: ":id/editar",
        component: DetailsRepresentative,
      },
    ],
  },
];
