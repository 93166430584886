const Dashboard = () => import("../layouts/dashboard.vue");
const FeedbackList = () => import("../pages/feedback/listFeedback.vue");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const feedbackRoutes = [
  {
    path: "/feedback",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: FeedbackList,
      },
    ],
  },
];
