const Dashboard = () => import("../layouts/dashboard.vue");
const DetailsAlert = () => import("../pages/alerts/detailsAlert.vue");
const ListAlerts = () => import("../pages/alerts/listAlerts");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const alertRoutes = [
  {
    path: "/notificacoes",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: ListAlerts,
      },
      {
        path: "criar",
        component: DetailsAlert,
      },
      {
        path: ":id/editar",
        component: DetailsAlert,
      },
    ],
  },
];
