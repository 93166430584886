import http from "../plugins/http";

const initState = () => ({
  callYou: {
    id: 0,
    periodo: null,
    horario: null,
    nome: "",
    telefone: "",
    cpfCnpj: null,
    estado: null,
    atendido: true,
    informacoes: null,
    responsavel: "",
    data_atendido: "",
  },
});

const state = {
  isLoading: false,
  showOverlay: false,
  scheduling: initState().callYou,
  schedules: [],
};

const mutations = {
  setLoading: (state, value) => (state.isLoading = value),
  setOverlay: (state, value) => (state.showOverlay = value),
  setSchedules(state, value) {
    state.schedules = value;
  },
  setOneScheduling(state, value) {
    state.scheduling = value;
  },
  setUpdatedScheduling(state, value) {
    const index = state.schedules.findIndex((e) => e.id == value.id);
    state.schedules.splice(index, 1, value);
  },
  setMoreSchedules(state, value) {
    state.schedules.push(...value);
  },
  resetSchedulingList(state) {
    state.schedules = [];
  },
};

const actions = {
  async getAllSchedules({ commit }) {
    const { data } = await http.get("scheduling/we-call-you?page=1");
    commit("setSchedules", data);
  },
  async getMoreSchedules({ commit }, page) {
    commit("setLoading", true);
    const { data } = await http.get(`scheduling/we-call-you?page=${page}`);

    commit("setMoreSchedules", data);
    commit("setLoading", false);
  },
  async getOneScheduling({ commit }, id) {
    const { data } = await http.get(`scheduling/we-call-you/${id}`);
    commit("setOneScheduling", data);
  },
  async addAccountable({ commit }, { id, responsavel }) {
    const { data } = await http.put(
      `scheduling/we-call-you/${id}/add-accountable`,
      {
        responsavel,
      }
    );
    commit("setUpdatedScheduling", data);
    commit(
      "setToastedSuccess",
      { message: "Responsável adiconado com sucesso" },
      { root: true }
    );
  },
  async markCompleted({ commit }, { id, informacoes }) {
    const { data } = await http.put(
      `scheduling/we-call-you/${id}/mark-completed`,
      {
        informacoes,
      }
    );
    commit("setUpdatedScheduling", data);
    commit(
      "setToastedSuccess",
      { message: "Considerações finais adiconadas com sucesso" },
      { root: true }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
