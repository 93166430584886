const Dashboard = () => import("../layouts/dashboard.vue");
const Operations = () => import("../pages/operations.vue");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const operationRoutes = [
  {
    path: "/operacoes",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: Operations,
      },
    ],
  },
];
