import http from "../plugins/http";

const initState = () => ({
  client: {
    id: 0,
    razao_social: "",
    cpf_cnpj: "",
    email: "",
    cliente_endereco: [{}],
    cliente_financeiro: [{}],
  },
});

const state = {
  isLoading: false,
  showOverlay: false,
  client: initState().client,
  clients: [],
  pending: [],
};

const mutations = {
  setLoading: (state, value) => (state.isLoading = value),
  setOverlay: (state, value) => (state.showOverlay = value),
  setPending(state, value) {
    state.pending = value;
  },
  setMorePending(state, value) {
    state.pending.push(...value);
  },
  setClients(state, value) {
    state.clients = value;
  },
  setMoreClients(state, value) {
    state.clients.push(...value);
  },
  setOneClient(state, value) {
    state.client = value;
  },
  setUpdatedClient(state, value) {
    const index = state.clients.findIndex((e) => e.id == value.id);
    state.clients.splice(index, 1, value);
  },
  setRemovePending(state, id) {
    state.pending = state.pending.filter((e) => e.id !== id);
  },
  resetClientList(state) {
    state.clients = [];
  },
  resetClient(state) {
    state.client = initState().client;
  },
};

const actions = {
  async getAllClients({ commit }) {
    const { data } = await http.get("client?page=1");
    commit("setClients", data);
  },
  async getMoreClients({ commit }, page) {
    commit("setLoading", true);
    const { data } = await http.get(`client?page=${page}`);

    commit("setMoreClients", data);
    commit("setLoading", false);
  },
  async getAllPending({ commit }) {
    const { data } = await http.get("client/pending?page=1");
    commit("setPending", data);
  },
  async getMorePending({ commit }, page) {
    commit("setLoading", true);
    const { data } = await http.get(`client/pending?page=${page}`);

    commit("setMorePending", data);
    commit("setLoading", false);
  },
  async getOneClient({ commit }, id) {
    const { data } = await http.get(`client/${id}`);
    commit("setOneClient", data);
  },
  async markCompleted({ commit, dispatch }, { id, form }) {
    try {
      await http.patch(`client/${id}/confirm-login`, form);
      commit("setRemovePending", id);
      await dispatch("getAllClients");
      commit(
        "setToastedSuccess",
        { message: "Cadastro do cliente confirmado" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Não foi possivel confirmar cadastro do cliente " },
        { root: true }
      );
    }
  },
  async updateInfo({ commit }, { id, form }) {
    try {
      const { data } = await http.put(`client/${id}`, form);
      commit("setOneClient", data);
      commit(
        "setToastedSuccess",
        { message: "Informações do cliente atualizadas" },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToastedError",
        { message: "Não foi possivel atualizar informações do cliente" },
        { root: true }
      );
    }
  },
  async deleteUnregisteredClient({ commit }, id) {
    try {
      await http.delete(`client/${id}`);
      commit(
        "setToastedSuccess",
        { message: "Cliente deletado" },
        { root: true }
      );
      commit("setRemovePending", id);
    } catch (error) {
      commit(
        "setToastedError",
        { message: "Não foi possivel deletar o cliente" },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
