import http from "../plugins/http";

const initState = () => ({
  email: {
    id: 0,
    nome: "",
    contato: "",
    cpf_cnpj: "",
    assunto: "",
    mensagem: "",
    destino: "",
    recebido: "",
    created_at: "",
    updated_at: "",
  },
});

const state = {
  isLoading: false,
  showOverlay: false,
  email: initState().email,
  emails: [],
};

const mutations = {
  setLoading: (state, value) => (state.isLoading = value),
  setLoadingSearch: (state, value) => (state.isLoadingSearch = value),
  setSearching: (state, value) => (state.isSearching = value),
  setOverlay: (state, value) => (state.showOverlay = value),
  setEmails(state, value) {
    state.emails = value;
  },
  setOneEmail(state, value) {
    state.email = value;
  },
  setUpdatedEmail(state, value) {
    const index = state.emails.findIndex((e) => e.id == value.id);
    state.emails.splice(index, 1, value);
  },
  setMoreEmails(state, value) {
    state.emails.push(...value);
  },
  setCatalogs(state, values) {
    state.catalogs = values;
  },
  setGroups(state, values) {
    state.groups = values;
  },
  resetEmailList(state) {
    state.emails = [];
  },
};

const actions = {
  async getAllEmails({ commit }) {
    commit("setSearching", false);
    const { data } = await http.get("email?page=1");
    commit("setEmails", data);
  },
  async getOneEmail({ commit }, id) {
    const { data } = await http.get(`email/${id}`);
    commit("setOneEmail", data);
  },
  async getMoreEmails({ commit }, page) {
    commit("setLoading", true);
    const { data } = await http.get(`email?page=${page}`);

    commit("setMoreEmails", data);
    commit("setLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
