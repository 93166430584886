const Dashboard = () => import("../layouts/dashboard.vue");
const List = () => import("../pages/brand");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const brandRoutes = [
  {
    path: "/marca",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: List,
      },
    ],
  },
];
