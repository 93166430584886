const Dashboard = () => import("../layouts/dashboard.vue");
const WeCallYouList = () => import("../pages/weCallYou/WeCallYouList.vue");
const WeCallYouDetails = () =>
  import("../pages/weCallYou/WeCallYouDetails.vue");

/**
 * @type {import('vue-router').RouteConfig[]}
 */
export const weCallYouRoutes = [
  {
    path: "/ligamos-para-voce",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        component: WeCallYouList,
      },
      {
        path: ":id/detalhes",
        component: WeCallYouDetails,
      },
    ],
  },
];
