<template>
  <div>
    <router-view />
    <custom-toast
      :title="toastedInfo.title"
      :message="toastedInfo.message"
      :visible="toastedInfo.visible"
      :variant="toastedInfo.variant"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import CustomToast from "./components/shared/CustomToast.vue";

export default {
  components: { CustomToast },
  computed: {
    ...mapState(["toastedInfo"]),
  },
};
</script>
