import Vue from "vue";
import Vuex from "vuex";
import alert from "./alert";
import auth from "./auth";
import box from "./box";
import client from "./client";
import email from "./email";
import product from "./product";
import representative from "./representative";
import weCallYou from "./weCallYou";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    hideSideBar: true,
    showOverlay: false,
    toastedInfo: {
      title: "",
      message: "",
      variant: "",
      visible: false,
    },
  },
  mutations: {
    setSideBar(state) {
      state.hideSideBar = !state.hideSideBar;
    },
    setToasted(state, values) {
      state.toastedInfo.title = values.title;
      state.toastedInfo.message = values.message;
      state.toastedInfo.variant = values.variant;
      state.toastedInfo.visible = values.visible;
    },
    setToastedSuccess(state, values) {
      state.toastedInfo.title = values.title || "SUCESSO";
      state.toastedInfo.message = values.message;
      state.toastedInfo.variant = values.variant || "success";
      state.toastedInfo.visible = true;
    },
    setToastedError(state, values) {
      state.toastedInfo.title = values.title || "ERRO";
      state.toastedInfo.message = values.message;
      state.toastedInfo.variant = values.variant || "danger";
      state.toastedInfo.visible = true;
    },
    hideToasted(state) {
      state.toastedInfo.visible = !state.toastedInfo.visible;
    },
    setOverlay: (state, value) => (state.showOverlay = value),
  },
  actions: {},
  modules: {
    auth,
    product,
    representative,
    box,
    email,
    alert,
    weCallYou,
    client,
  },
});
