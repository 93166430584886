export function formatRepresentative(representativeObj) {
  let formatedObj = {};

  Object.entries(representativeObj).forEach(([key, value]) => {
    if (key.startsWith("whatsapp")) {
      formatedObj[key] = Number(value);
    } else {
      formatedObj[key] = value;
    }
  });

  return formatedObj;
}
