import http from "../plugins/http";

const initState = () => ({
  product: {
    catalogo_id: 0,
    grupo_id: 0,
    codigo_produto: "",
    peso: 0,
    referencia: "",
    descricao_completa: "",
    exibir_ipi: true,
    unidade: "",
    embalagem: 0,
    embalagem_unidade: "",
    tags: "",
    exibir: false,
    novidade: false,
    ativo: false,
    destaque: false,
    atributos: [],
    conversao: [],
    veiculo: {
      id: 0,
      modelo_id: 0,
      eixo: "",
      ano_inicial: 0,
      ano_final: 0,
    },
  },
});

const state = {
  isLoading: false,
  isLoadingSearch: false,
  isSearching: false,
  showOverlay: false,
  product: initState().product,
  products: [],
  catalogs: [],
  groups: [],
};

const mutations = {
  setLoading: (state, value) => (state.isLoading = value),
  setLoadingSearch: (state, value) => (state.isLoadingSearch = value),
  setSearching: (state, value) => (state.isSearching = value),
  setOverlay: (state, value) => (state.showOverlay = value),
  setProducts(state, value) {
    state.products = value;
  },
  setOneProduct(state, value) {
    state.product = value;
  },
  setUpdatedProduct(state, value) {
    const index = state.products.findIndex((e) => e.id == value.id);
    state.products.splice(index, 1, value);
  },
  setMoreProducts(state, value) {
    state.products.push(...value);
  },
  setCatalogs(state, values) {
    state.catalogs = values;
  },
  setGroups(state, values) {
    state.groups = values;
  },
  resetProductList(state) {
    state.products = [];
  },
};

const actions = {
  async getAllProducts({ commit }) {
    commit("setSearching", false);
    const { data } = await http.get("product?page=1");
    commit("setProducts", data);
  },
  async getOneProduct({ commit }, id) {
    const { data } = await http.get(`product/details/${id}`);
    commit("setOneProduct", data);
  },
  async getMoreProducts({ commit }, page) {
    commit("setLoading", true);
    const { data } = await http.get(`product?page=${page}`);

    commit("setMoreProducts", data);
    commit("setLoading", false);
  },
  async searchProducts({ commit }, search) {
    commit("setSearching", true);
    commit("setLoadingSearch", true);

    const { data } = await http.get(`product/search?page=1&search=${search}`);
    commit("setProducts", data);
    commit("setLoadingSearch", false);
  },
  async getMoreSearch({ commit }, { page, search }) {
    commit("setSearching", true);
    commit("setLoading", true);

    const { data } = await http.get(
      `product/search?page=${page}&search=${search}`
    );

    commit("setMoreProducts", data);
    commit("setLoading", false);
  },
  async getProductCatalogs({ commit }) {
    const { data } = await http.get("catalog");
    commit("setCatalogs", data);
  },
  async getProductGroup({ commit }) {
    const { data } = await http.get("catalog/indexes");
    commit("setGroups", data);
  },
  async updateProduct({ commit }, values) {
    try {
      values.embalagem = parseInt(values.embalagem);
      const { data } = await http.put(
        `product/updateProduct/${values.id}`,
        values
      );

      commit("setUpdatedProduct", data);
      commit(
        "setToasted",
        {
          title: "Sucesso",
          message: "Produto atualizado com sucesso!",
          variant: "success",
          visible: true,
        },
        { root: true }
      );
    } catch (err) {
      commit(
        "setToasted",
        {
          title: "ERRO",
          message: err.response.data.error,
          variant: "danger",
          visible: true,
        },
        { root: true }
      );
    }
  },
  async hideProduct({ commit }, { id, values }) {
    const { data } = await http.patch(`product/${id}/disable`, values);
    commit("setUpdatedProduct", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
